import {
  Account,
  Connection,
  PublicKey,
  Keypair,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner } from "@/libs/solanaConnection";
import { anchor_init, get_pda, prepare_ata } from "@/libs/solanaProgram";
import { signAndSendTransaction, signAndSendMultipleTransactions } from "@/libs/wallet";
const mpl_token_metadata = require('@metaplex-foundation/mpl-token-metadata');

import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createTransferCheckedInstruction, MintLayout  } from "@solana/spl-token";

import _idl from "@/IDL.json";
const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('7DujNcbL1Q6tU4dvVjoc7sbYxF7CxC2mRwm1NWDQ94vo');

var connection = null;


import axios from 'axios';
import bs58 from "bs58";

const VAULT_ACCOUNT_SEED = 'collection owner';


let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}
	
/**
 * 
 * Find the PDA for the metadata
 * 
 */
async function find_pda(mint_pubkey) {
	
	const [pda_metadata, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint_pubkey.toBuffer(),
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	return pda_metadata;
}

/**
 * 
 * Find the PDA for the edition
 * 
 */
async function find_pda_edition(mint_pubkey) {
	
	const [pda_edition, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint_pubkey.toBuffer(),
			Buffer.from('edition'),
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	return pda_edition;
}

/**
 * 
 * Find the PDA for the collection owner
 * 
 */
export async function find_pda_collection_owner() {
	
	const [pda_collection_owner, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('collection owner'),
		],
		program_id
	);
	
	return pda_collection_owner;
}

/**
 * 
 * Find the PDA for the collection owner (authority)
 * 
 */
async function find_pda_collection_authority() {
	
	const [pda_collection_authority, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('collection owner authority'),
		],
		program_id
	);
	
	return pda_collection_authority;
}

/**
 * 
 * Create the instruction to create the metadata
 * 
 */
async function prepare_metadata_instruction(wallet_address, mint, nft_name) {
	
	var pda_metadata = await find_pda(mint.publicKey);
	
	var accounts = {
		metadata: pda_metadata, // OK
		mint: mint.publicKey, // OK
		mintAuthority: wallet_address,
		payer: wallet_address,
		updateAuthority: wallet_address,
	};
	
	
	var metadata = {
		name: 'DM',
		symbol: 'DM',
		uri: 'https://back.the-cocktail-crew.com/storage/dm/'+nft_name+'.json',
		sellerFeeBasisPoints: 0,
		creators: null,
		uses: null,
		collection: null,
	};
	
	var args = {
		createMetadataAccountArgsV2: {
			data: metadata,
			isMutable: false,
		},
	};
	
	var metadata_instruction = await mpl_token_metadata.createCreateMetadataAccountV2Instruction(accounts,args);
	
	return metadata_instruction;
}

async function prepare_create_account_instruction(wallet_address, mint) {
	
	return await SystemProgram.createAccount({
		fromPubkey: wallet_address,
		newAccountPubkey: mint.publicKey,
		lamports: await Token.getMinBalanceRentForExemptMint(connection),
		space: MintLayout.span,
		programId: TOKEN_PROGRAM_ID,
	});
}

async function prepare_init_mint_instruction(wallet_address, mint) {
	
	// var pda = await find_pda_collection_owner(mint.publicKey);
	
	return await Token.createInitMintInstruction(
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		0,
		wallet_address, // mint auth
		wallet_address // freeze auth
		// pda,
		// pda,
	);
}

async function prepare_create_master_edition_v3_instruction(wallet_address, mint) {
	
	// var pda = await find_pda_collection_owner(mint.publicKey);
	
	var accounts = {
		edition: await find_pda_edition(mint.publicKey),
		mint: mint.publicKey,
		updateAuthority: wallet_address,
		mintAuthority: wallet_address,
		payer: wallet_address,
		metadata: await find_pda(mint.publicKey)
	};
	
	var args = {
		createMasterEditionArgs: {
			maxSupply: 0,
		},
	};
	
	return await mpl_token_metadata.createCreateMasterEditionV3Instruction(accounts, args);
}

async function prepare_create_ata_instruction(wallet_address, mint, destination) {
	
	// create associated token account
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		destination,  // to
		true
	);
	
	const create_ata_instruction = Token.createAssociatedTokenAccountInstruction(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		ata_mint,
		destination, // to
		wallet_address // signer
	);
	
	return create_ata_instruction;
}

async function prepare_create_mintto_instruction(wallet_address, mint, to_wallet) {
	
	// create associated token account
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		to_wallet,  // to
		true
	);
	
	const mint_to_instruction = Token.createMintToInstruction(TOKEN_PROGRAM_ID, mint.publicKey, ata_mint, wallet_address, [], 1);
	
	return mint_to_instruction;
}

export async function create_collection(wallet_provider, wallet_address, metadata) {
	
	if(!connection)
		connection = await establishConnection();
	
	var mint = await Keypair.generate();
	wallet_address = new PublicKey(wallet_address);
	
	var metadata_instruction = await prepare_metadata_instruction(wallet_address, mint, metadata, null);
	var create_account_instruction = await prepare_create_account_instruction(wallet_address, mint);
	var init_mint_instruction = await prepare_init_mint_instruction(wallet_address, mint);
	var create_ata_instruction = await prepare_create_ata_instruction(wallet_address, mint);
	var mint_to_instruction = await prepare_create_mintto_instruction(wallet_address, mint);
	var create_master_edition_v3_instruction = await prepare_create_master_edition_v3_instruction(wallet_address, mint);
	
	// set authority
	// var update_auth = await find_pda_collection_owner();
	
	// console.log("Update auth", update_auth.toString());
	
	// const set_authority_instruction = Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, mint.publicKey, update_auth, 0, wallet_provider.publicKey, [])
	
	var transaction = new Transaction();
	
	transaction.add(create_account_instruction);
	transaction.add(init_mint_instruction);
	transaction.add(metadata_instruction);
	transaction.add(create_ata_instruction);
	transaction.add(mint_to_instruction);
	transaction.add(create_master_edition_v3_instruction);
	// transaction.add(set_authority_instruction);
	
	transaction.feePayer = wallet_address;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(mint);
	
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	console.log('collection address', mint.publicKey.toString(), 'signature', signature);
	
	return mint.publicKey;	
}

/*
async function set_autority_to_pda(collection_address) {
	
	var update_auth = await find_pda_collection_owner();
	
	console.log("Update auth", update_auth.toString());
	
	var current_authority = new PublicKey('7tGJMU6N65UXRn9qPBpRvJZBcviwSSi52TUXYZg3AmWM');
	
	// const set_authority_instruction = Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, collection_address, update_auth, 0, wallet_provider.publicKey, [])
	const set_authority_instruction = Token.createSetAuthorityInstruction(TOKEN_PROGRAM_ID, collection_address, update_auth, 0, current_authority, [])
	
	var transaction = new Transaction();
	
	transaction.add(set_authority_instruction);
	
	transaction.feePayer = new PublicKey(wallet_provider.publicKey);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(wallet_provider);
	
	let signature = await connection.sendRawTransaction(
		transaction.serialize(),
		{ skipPreflight: true }
	);	
	
	console.log('signature', signature);
}
*/

/**
 * 
 * Create vault
 * 
 */
export async function mint_and_send_nft(wallet_provider, wallet_address, nft_name, to_wallet) {
	
	if(!connection)
		connection = await establishConnection();
	
	let mint = await Keypair.generate();
	wallet_address = new PublicKey(wallet_address)
	to_wallet = new PublicKey(to_wallet)
	
	let metadata_instruction = await prepare_metadata_instruction(wallet_address, mint, nft_name);
	let create_account_instruction = await prepare_create_account_instruction(wallet_address, mint);
	let init_mint_instruction = await prepare_init_mint_instruction(wallet_address, mint);
	let create_ata_instruction = await prepare_create_ata_instruction(wallet_address, mint, to_wallet);
	// let create_mint_nft_instruction = await prepare_program_mint_instruction(wallet_provider, wallet_address, mint, collection_address, escrow_address, mint_type, escrow_wl_address);
	// var create_master_edition_v3_instruction = await prepare_create_master_edition_v3_instruction(wallet_address, mint);
	var mint_to_instruction = await prepare_create_mintto_instruction(wallet_address, mint, to_wallet);
	
	let transaction = new Transaction();
	
	transaction.add(create_account_instruction);
	transaction.add(init_mint_instruction);
	transaction.add(create_ata_instruction);
	transaction.add(metadata_instruction);
	transaction.add(mint_to_instruction);
	// transaction.add(create_master_edition_v3_instruction);
	
	transaction.feePayer = new PublicKey(wallet_provider.publicKey);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	transaction.sign(mint);
		
	var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
	
	return signature;	
}
